export const makeDummySupplementaryInstance = (cvr, period) => {
    const reportingPeriodStartDate = period.startDate.toISOString().slice(0, 10);
    const reportingPeriodEndDate = period.endDate.toISOString().slice(0, 10);

    return `
        <xbrli:xbrl xmlns:xbrli="http://www.xbrl.org/2003/instance" xmlns:dst="http://xbrl.dcca.dk/dst"
            xmlns:cmn="http://xbrl.dcca.dk/cmn" xmlns:sob="http://xbrl.dcca.dk/sob"
            xmlns:link="http://www.xbrl.org/2003/linkbase" xmlns:iso4217="http://www.xbrl.org/2003/iso4217"
            xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:ref="http://www.xbrl.org/2006/ref"
            xmlns:xhlld="http://www.w3.org/1999/xhtml/datatypes/" xmlns:arr="http://xbrl.dcca.dk/arr"
            xmlns:ixt="http://www.xbrl.org/inlineXBRL/transformation/2010-04-20"
            xmlns:xbrldt="http://xbrl.org/2005/xbrldt"
            xmlns:basis="http://xbrl.dcca.dk/Regnskab%202.0%20Basis"
            xmlns:ifrs="http://xbrl.iasb.org/taxonomy/2009-04-01/ifrs"
            xmlns:ix="http://www.xbrl.org/2008/inlineXBRL" xmlns:xhtml="http://www.w3.org/1999/xhtml"
            xmlns:mrv="http://xbrl.dcca.dk/mrv" xmlns:xl="http://www.xbrl.org/2003/XLink"
            xmlns:fsa="http://xbrl.dcca.dk/fsa" xmlns:xbrldi="http://xbrl.org/2006/xbrldi"
            xmlns:gsd="http://xbrl.dcca.dk/gsd" xmlns:xs="http://www.w3.org/2001/XMLSchema"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <link:schemaRef xlink:type="simple"
                xlink:href="http://archprod.service.eogs.dk/taxonomy/20231001/entryDKGAAPRequiredInformation20231001.xsd" />
            <xbrli:context id="ctx-1">
                <xbrli:entity>
                    <xbrli:identifier scheme="http://www.dcca.dk/cvr">${cvr}</xbrli:identifier>
                </xbrli:entity>
                <xbrli:period>
                    <xbrli:startDate>${reportingPeriodStartDate}</xbrli:startDate>
                    <xbrli:endDate>${reportingPeriodEndDate}</xbrli:endDate>
                </xbrli:period>
            </xbrli:context>
            <gsd:InformationOnTypeOfSubmittedReport contextRef="ctx-1">Påkrævet information</gsd:InformationOnTypeOfSubmittedReport>
            <gsd:IdentificationNumberCvrOfReportingEntity contextRef="ctx-1">${cvr}</gsd:IdentificationNumberCvrOfReportingEntity>
            <gsd:ReportingPeriodStartDate contextRef="ctx-1">${reportingPeriodStartDate}</gsd:ReportingPeriodStartDate>
            <gsd:ReportingPeriodEndDate contextRef="ctx-1">${reportingPeriodEndDate}</gsd:ReportingPeriodEndDate>
        </xbrli:xbrl>
    `.trim();
};